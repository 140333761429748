<template>
  <div class="h-100">
    <div class="h-100" v-if="true">
      <v-card class="d-block shadow-none rounded-lg h-100" v-if="isDataLoaded">
        <v-card-title
          class="d-flex justify-content-between font-weight-bolder text-dark-65 py-2 mb-1"
        >
          <div class="pt-2">{{ item.title }}</div>
          <div>
            <b-btn
              v-if="this.serverData.values.other_exists"
              @click="$refs.otherCountriesModal.toggleModal"
              size="sm"
              variant="light-skype"
              >Show Other countries</b-btn
            >
          </div>
        </v-card-title>
        <v-card-subtitle class="font-weight-bolder text-dark-25 py-2 mb-6">{{
          $t("tracking_shipping_progress_for_a_global_network_of_destinations")
        }}</v-card-subtitle>
        <v-card-text class="d-block ma-0 pa-0 position-relative">
          <apexchart
            type="bar"
            height="300"
            :options="chartOptions"
            :series="series"
          ></apexchart>
          <!-- <div
            style="
              position: absolute;
              right: 8%;
              bottom: 57px;
              width: 19px;
              height: 120px;
              cursor: pointer;
            "
            @click="$refs.otherCountriesModal.toggleModal"
          ></div> -->
        </v-card-text>
      </v-card>
    </div>
    <v-skeleton-loader v-else type="card"></v-skeleton-loader>
    <!-- <component
      ref="otherCountriesModal"
      v-bind:is="fulfillmentPerCountryColumnChartOtherCountriesModal"
      :serverData="serverData"
    ></component> -->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
// import FulfillmentPerCountryColumnChartOtherCountriesModal from "@/own/components/dashboard/FulfillmentPerCountryColumnChartOtherCountriesModal.vue";

export default {
  name: "FulfillmentPerCountryColumnChart",
  props: ["item", "filterData", "updateChart"],
  data: () => ({
    serverData: null,
    // fulfillmentPerCountryColumnChartOtherCountriesModal:
    //   FulfillmentPerCountryColumnChartOtherCountriesModal,
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    loadDataFromServer() {
      this.serverData = null;
      let data = this.filterData;
      ApiService.post(`${this.item.url}`, data)
        .then((response) => {
          this.serverData = response.data.chart;
        })
        .catch(() => {});
    },
  },
  computed: {
    isDataLoaded: function () {
      return !!this.serverData;
    },
    chartOptions: function () {
      return {
        chart: {
          type: "bar",
          toolbar: {
            show: true,
          },
          events: {
            // dataPointSelection: function (event, chartContext, config) {
            //   console.log(event);
            //   console.log(chartContext);
            //   console.log(config);
            // },
            // seriesIndex: function (event, chartContext, config) {
            //   console.log(event);
            //   console.log(chartContext);
            //   console.log(config);
            // },
          },
        },

        fill: {
          colors: ["#0093f5"],
          opacity: 1,
        },
        plotOptions: {
          bar: {
            borderRadius: 7,
            columnWidth: "25%",
            dataLabels: {
              position: "top", // top, center, bottom// offset by 20px
            },
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
          borderColor: "#b0b4c2",
          strokeDashArray: 4,
          position: "back",
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          offsetY: -20,
          style: {
            fontSize: "12px",
            colors: ["#304758"],
          },
        },

        xaxis: {
          labels: {
            show: true,
            style: {
              colors: "#b1b5c3",
              fontSize: "12px",
            },
          },
          axisBorder: {
            show: false,
          },
          position: "bottom",
          tooltip: {
            enabled: false,
          },
          categories: this.serverData.values.categories,
        },

        yaxis: {
          axisBorder: {
            show: false,
          },
          labels: {
            show: true,
            formatter: function (val) {
              return val + "";
            },
            style: {
              colors: ["#afb2c1"],
              fontSize: "12px",
              fontWeight: 500,
            },
          },
        },

        title: {
          floating: true,
          offsetY: 330,
          align: "center",
          style: {
            color: "#444",
          },
        },

        tooltip: {
          enabled: false,
        },
      };
    },
    series: function () {
      let series = this.serverData.values.series.map((serie) => {
        return serie.data[0];
      });
      return [{ data: series, name: "" }];
    },
  },
  watch: {
    filterData: {
      deep: true,
      immediate: false,
      handler(newData, oldData) {
        if (JSON.stringify(newData) !== JSON.stringify(oldData)) {
          this.serverData = null;
          this.loadDataFromServer();
        }
      },
    },
    updateChart: function (val) {
      if (val !== 0) {
        this.loadDataFromServer();
      }
    },
  },
};
//TODO::Dasboard:: delete old component
</script>
<style lang="scss">
.other_colum_floated_block {
  position: absolute;
  right: 50px;
  bottom: 57px;
  width: 19px;
  height: 120px;
}
</style>
